<script>
  import {Doughnut} from "vue-chartjs";

  export default {
    extends: Doughnut,
    name: 'Top10AgenciesByRevenueCY',
    props: ['orders'],
    data() {
      return {
        firstYearDay: new Date(new Date().getFullYear(), 0, 1),
        groupedOrders: [],
        barChartData: {
          labels: [],
          datasets: [
            {
              backgroundColor: [],
              data: []
            },
          ]
        },
        barChartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom'
            },
            datalabels: {
              backgroundColor: function (context) {
                return context.dataset.backgroundColor;
              },
              borderColor: 'white',
              borderRadius: 25,
              borderWidth: 1,
              color: 'white',
              display: function (context) {
                let dataset = context.dataset;
                return dataset.data[context.dataIndex];
              },
              font: {
                weight: 'normal'
              },
              padding: 3,
              formatter: Math.round,
              anchor: 'center',
            }
          }
        }
      }
    },
    methods: {
      groupOrders() {
        let orders = this.orders;
        let agencies = []
        let qts = []
        let labels = []
        let colors = ['rgba(100, 200, 15, 0.8)',
          'rgba(255, 12, 36, 0.8)',
          'rgba(150, 100, 150, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(147, 147, 64, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(150, 60, 80, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(201, 85, 12, 0.8)',
          'rgba(99, 100, 128, 0.8)']

        //Grouping all orders by agency
        function groupItemBy(array, property) {
          var hash = {},
            props = property.split('.');
          for (var i = 0; i < array.length; i++) {
            var key = props.reduce(function (acc, prop) {
              return acc && acc[prop];
            }, array[i]);
            if (!hash[key]) hash[key] = [];
            hash[key].push(array[i]);
          }
          return hash;
        }

        let grouped = Object.values(groupItemBy(orders, 'agency.agencyId'));

        let totalRevenueByAgency

        for (let i = 0; i < grouped.length; i++) {
          totalRevenueByAgency = grouped[i]
            .map((order) => order.totalCost)
            .reduce(function (previous, current) {
              return previous + current;
            }, 0)

          let agencyObj = {agencyName: grouped[i][0].agency.agencyName, agencyRevenue: totalRevenueByAgency}
          agencies.push(agencyObj)
        }

        agencies.sort((a, b) => {
          return b.agencyRevenue - a.agencyRevenue;
        });
        let topAgencies = agencies.slice(0, 10);


        let top10TotalRevenue
        for (let i = 0; i < topAgencies.length; i++) {
          top10TotalRevenue = topAgencies.map((agency) => agency.agencyRevenue)
            .reduce(function (previous, current) {
              return previous + current;
            }, 0)
        }

        let totalRevenue
        for (let i = 0; i < orders.length; i++) {
          totalRevenue = orders.map((order) => order.totalCost)
            .reduce(function (previous, current) {
              return previous + current;
            }, 0)
        }

        labels.push(this.$t('dashboard-items.other-agencies')+' (' + ((totalRevenue - top10TotalRevenue) * 100 / totalRevenue).toFixed(1) + '%)')
        qts.push(totalRevenue - top10TotalRevenue)

        for (let i = 0; i < topAgencies.length; i++) {
          labels.push(topAgencies[i].agencyName + ' (' + (topAgencies[i].agencyRevenue * 100 / totalRevenue).toFixed(1) + '%)')
          qts.push(topAgencies[i].agencyRevenue)
        }

        this.barChartData.datasets[0].data = qts;
        this.barChartData.datasets[0].backgroundColor = colors;
        this.barChartData.labels = labels;
      }
    },
    async mounted() {
      this.groupOrders()
      this.renderChart(this.barChartData, this.barChartOptions);
    },
  };
</script>

<style scoped>

</style>
