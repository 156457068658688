<script>
  import {Line} from "vue-chartjs";

  export default {
    extends: Line,

    name: 'YearlyIncomeChart',
    props: ['orders'],
    data() {
      return {
        groupedOrders: [],
        barChartData: {
          labels: [],
          datasets: [
            {
              fill: false,
              backgroundColor: "rgba(54, 162, 235, 0.9)",
              data: [],
              cubicInterpolationMode: 'monotone',
              pointRadius: 5,
              borderColor: "rgba(255, 99, 132, 0.8)",
            },

          ]
        },
        barChartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              backgroundColor: 'white',
              borderRadius: 4,
              color: 'rgba(255, 99, 132, 0.8)',
              formatter: Math.round,
              padding: 2,
              anchor: 'start',
              align: 'start',
            },
          }
        }
      }
    },
    methods: {
      groupOrders() {
        let orders = this.orders;
        let years = []

        function groupItemBy(array, property) {
          var hash = {},
            props = property.split('.');
          for (var i = 0; i < array.length; i++) {
            var key = props.reduce(function (acc, prop) {
              return acc && acc[prop];
            }, array[i]);
            if (!hash[key]) hash[key] = [];
            hash[key].push(array[i]);
          }
          return hash;
        }

        let grouped = Object.values(groupItemBy(orders, 'orderDate'));

        //Adding grouped orders to year array, merging to unique years and sorting values.
        for (let i = 0; i < grouped.length; i++) {
          years.push(new Date(grouped[i][0].orderDate).getFullYear())
        }
        const uniqueYears = [...new Set(years)].sort(function compareNumbers(a, b) {
          return a - b;
        })

        //Getting total cost for each year
        let totalRevenueByYear = []
        for (let i = 0; i < uniqueYears.length; i++) {
          totalRevenueByYear.push(orders.filter(e => new Date(e.orderDate).getFullYear() === uniqueYears[i])
            .map((order) => order.totalCost)
            .reduce(function (previous, current) {
              return previous + current;
            }, 0).toFixed(0))
        }

        //Pushing the calculated data to chart arrays (data/labels separately)
        this.barChartData.datasets[0].data = totalRevenueByYear;
        this.barChartData.labels = uniqueYears;
      }
    },
    async mounted() {
      this.groupOrders()
      this.renderChart(this.barChartData, this.barChartOptions);
    },
  };
</script>

<style scoped>

</style>
