import {defineStore} from 'pinia'
import {AXIOS} from "../../http-commons";
import EventBus from "../common/EventBus";
import {Message} from "element-ui"
import 'element-ui/lib/theme-chalk/index.css';

export const useUser = defineStore('userStore', {
  id: 'userDB',

  state: () => ({
    loading: false,
    userDB: [],
    userSettings: []
  }),
  actions: {
    patchSettings(setting) {
      console.log("set", setting)
      this.userSettings = setting;
    },
    async getUserSettingsAsync() {
      let params = new URLSearchParams();
      params.append('userId', JSON.parse(localStorage.getItem('user')).id);
      let us = await AXIOS.get('/api/getUserSettings', {params})
      this.userSettings = us.data
      return us;
    },
    async getUserAsync() {
      this.loading = true;
      let params = new URLSearchParams();
      params.append('userId', JSON.parse(localStorage.getItem('user')).id);
      let user = await AXIOS.get('/api/user', {params})
        .then(response => {
            this.userDB = response.data;
            this.loading = false;
          },
          error => {
            if (error.response && error.response.status === 403) {
              this.logoutNotification()
              EventBus.dispatch("logout");
            }
          })
      // this.userDB = user.data
      // this.loading = false;
      return user;
    },
    populateUser() {
      this.loading = true
      let params = new URLSearchParams();
      params.append('userId', JSON.parse(localStorage.getItem('user')).id);
      AXIOS.get('/api/user', {params})
        .then(response => {
            this.userDB = response.data;
          },
          error => {
            if (error.response && error.response.status === 403) {
              this.logoutNotification()
              EventBus.dispatch("logout");
            }
          })
    },
    logoutNotification() {
      Message({
        message: 'Current session has expired. Please, login again',
        type: 'error',
        duration: 5000
      });
    },
  }
})
