import {defineStore} from 'pinia'
import {AXIOS} from "../../http-commons";
import EventBus from "../common/EventBus";

export const useOrders = defineStore('ordersStore', {
  id: 'orders',
  state: () => ({
    $i18n: {},
    loading: false,
    orders: [],
    recentOrders: [],
    orderQty: 5,
    notifications: []
  }),
  getters: {
    i18n() {
      console.log("i18: ", this.$i18n.global)
      return this.$i18n.global;
    },
  },
  actions: {
    addOrder(order) {
      this.orders.unshift(order);
      this.orders.sort((a, b) => (a.orderDate > b.orderDate) ? -1 : 1);
    },
    patchOrder(order) {
      let elIndex = this.orders.findIndex((elem => elem.orderId === order.orderId));
      this.orders[elIndex] = order;
      this.orders.sort((a, b) => (a.orderDate > b.orderDate) ? -1 : 1);
      this.recentOrders = this.orders.slice(0, this.orderQty)
    },
    deleteOrder(orderId) {
      let elIndex = this.orders.findIndex((elem => elem.orderId === orderId));
      this.orders.splice(elIndex, 1);
    },
    deleteMultipleOrders(orderList) {
      for (let i = 0; i < orderList.length; i++) {
        let elIndex = this.orders.findIndex((elem => elem.orderId === orderList[i].orderId));
        this.orders.splice(elIndex, 1);
      }
    },
    dischargeOrdersDebt(debtOrders, status, paymentDate, payer) {
      for (let i = 0; i < debtOrders.length; i++) {
        console.log("iteration: ", debtOrders[i].orderId)
        let elIndex = this.orders.findIndex((elem => elem.orderId === debtOrders[i].orderId));
        this.orders[elIndex] = debtOrders[i];
      }
      this.orders.sort((a, b) => (a.orderDate > b.orderDate) ? -1 : 1);
    },
    async getOrdersAsync() {
      this.loading = true;
      let params = new URLSearchParams();
      params.append('userId', JSON.parse(localStorage.getItem('user')).id)
      let res = AXIOS.get('/api/getAllOrders', {params})
      let ords = await res
      this.orders = ords.data
      this.orders.sort((a, b) => (a.orderDate > b.orderDate) ? -1 : 1);
      this.loading = false;
      console.log("finished Async orders")
      return res
    },
    populateOrders() {
      this.loading = true;
      let params = new URLSearchParams();
      params.append('userId', JSON.parse(localStorage.getItem('user')).id)
      AXIOS.get('/api/getAllOrders', {params})
        .then(response => {
          this.orders = response.data;
          this.orders.sort((a, b) => (a.orderDate > b.orderDate) ? -1 : 1);
          this.getRecentOrders();
          this.loading = false;
        })
    },
    deleteNotification(notification) {
      let elIndex = this.notifications.findIndex((elem => elem.notificationId === notification.notificationId));
      this.notifications.splice(elIndex, 1);
    },
    getRecentOrders() {
      this.recentOrders = this.orders.slice(0, this.orderQty)
    },
    generateNotifications(tasks, fileStorage, dataStorage) {
      let text;
      let type;
      let messages = [];
      let types = [];
      let tomorrow = new Date();
      tomorrow.setDate(new Date().getDate() + 2);
      tomorrow.setHours(0, 0, 0, 0)
      let urgentOrders = this.orders
        .filter(e => e.status.statusName === 'In progress' || e.status.statusName === 'New')
        .filter(e => new Date(e.finishTime) < tomorrow);
      if (urgentOrders.length) {
        // text = this.i18n.t('messages.you-have') + ' ' + urgentOrders.length + ' ' + this.i18n.t('messages.urgent-orders')
        text = 'You have ' + urgentOrders.length + ' urgent orders'
        type = 'order'
        messages.push(text)
        types.push(type)
      }
      let activeTasks = tasks
        .filter(e => e.taskActive)
        .filter(e => new Date(e.startTime).getTime() <= new Date().getTime() && new Date(e.dueTime).getTime() >= new Date().getTime());
      if (activeTasks.length) {
        text = 'You have ' + urgentOrders.length + ' active tasks'
        // text = this.$t('messages.you-have') + activeTasks.length + this.$t('messages.active-tasks')
        type = 'task'
        messages.push(text)
        types.push(type)
      }
      console.log("data: ", dataStorage)
      if (dataStorage >= 90) {
        text = 'Data storage short: ' + dataStorage.toFixed(0) + '%'
        // text = this.$t('messages.data') + ' ' + this.$t('messages.storage-short') + ' ' + dataStorage.toFixed(0) + '%'
        type = 'dataStorage';
        messages.push(text)
        types.push(type)
      }
      console.log("file: ", fileStorage)
      if (fileStorage >= 90) {
        text = 'File storage short: ' + fileStorage.toFixed(0) + '%'
        // text = this.$t('messages.file') + ' ' + this.$t('messages.storage-short') + ' ' + fileStorage.toFixed(0) + '%'
        type = 'fileStorage';
        messages.push(text)
        types.push(type)
      }
      console.log("msg: ", messages)
      let params = new URLSearchParams();
      params.append('userId', JSON.parse(localStorage.getItem('user')).id);
      params.append('types', types);
      params.append('messages', messages);
      AXIOS.post('/api/generateNotifications', params)
        .then(response => {
            this.notifications = response.data;
          },
          error => {
            if (error.response && error.response.status === 403) {
              EventBus.dispatch("logout");
            }
          })
    },
  }
})
