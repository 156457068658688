<template>
  <Loader v-if="loading" type="dashboard"></Loader>
  <v-container id="dashboard" fluid tag="section" v-else-if="!loading">
    <v-row justify="center">
      <v-col cols="12" md="4" sm="12" lg="4">
        <base-material-stats-card
          color="warning"
          icon="mdi-poll"
          :title="$t('dashboard-items.revenue-total')"
          :value="'\u20B4 '+calculateRevenue"
          sub-icon="mdi-plus"
          sub-icon-color="success"
          :sub-text="(calculateYTDRevenue*100/totalRevenue).toFixed(1)+ '% '+$t('dashboard-items.this-year') +' (\u20B4 '+calculateYTDRevenue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')+')'">
        </base-material-stats-card>

        <base-material-stats-card
          color="info"
          icon="mdi-poll"
          :title="$t('dashboard-items.current-month-revenue')"
          :value="'\u20B4 '+calculateRevenueThisMonth"
          :sub-icon="calculateRelativeChanges>=0?'mdi-arrow-up':'mdi-arrow-down'"
          :sub-icon-color="calculateRelativeChanges>=0?'success':'error'"
          :sub-text="calculateRelativeChanges>=0?'+'+calculateRelativeChanges+ '% '+$t('dashboard-items.up') + ' '+ $t('dashboard-items.compared-last-month'):calculateRelativeChanges+ '% '+$t('dashboard-items.down') + ' '+ $t('dashboard-items.compared-last-month')">
        </base-material-stats-card>

        <!--        <base-material-stats-card-->
        <!--          color="info"-->
        <!--          icon="mdi-poll"-->
        <!--          :title="$t('dashboard-items.current-month-revenue')"-->
        <!--          :value="'\u20B4 '+calculateRevenueThisMonth"-->
        <!--          :sub-icon="calculateRelativeChanges>=0?'mdi-arrow-up':'mdi-arrow-down'"-->
        <!--          :sub-icon-color="calculateRelativeChanges>=0?'success':'error'"-->
        <!--          :sub-text="calculateRelativeChanges>=0?calculateRelativeChanges+ '% '+$t('dashboard-items.up') + ' '+ $t('dashboard-items.compared-last-month'):calculateRelativeChanges+ '% '+$t('dashboard-items.down') + ' '+ $t('dashboard-items.compared-last-month')">-->
        <!--        </base-material-stats-card>-->

        <base-material-stats-card
          color="primary"
          icon="mdi-clipboard-list-outline"
          :title="$t('dashboard-items.orders-total')"
          :value=orders.length.toString()
          sub-icon="mdi-plus"
          sub-icon-color="success"
          :sub-text="YTDOrders.length+ ' '+$t('dashboard-items.orders-this-year')+' ('+'+'+(YTDOrders.length*100/orders.length).toFixed(1)+'%)'"/>
      </v-col>

      <v-col cols="12" md="8">
        <v-card elevation="2">
          <v-card-title>{{$t('dashboard-items.current-month-income-comparison')}}, {{"\u20B4"}}
            ({{$t('dashboard-items.avg')}} {{monthlyAvg}})
            <v-spacer></v-spacer>
            <v-select v-model="monthNo" :items="months" item-text="name" item-value="monthNo"
                      :label="$t('dashboard-items.select-month')"
                      v-on:change="changeMonth()" prepend-icon="mdi-calendar-month-outline">
            </v-select>
          </v-card-title>
          <v-card-text>
            <CurrentMonthComparisonChart :orders="orders" v-on:average="monthlyAvg=$event"
                                         ref="currMonthCompChart"></CurrentMonthComparisonChart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card elevation="2">
          <v-card-title></v-card-title>
          <v-card-title>{{$t('dashboard-items.revenue-by-months')}} ({{selectedYear}}), {{"\u20B4"}}
            <v-spacer></v-spacer>
            <v-select v-model="selectedYear" :items="calculateUniqueYears"
                      :label="$t('dashboard-items.select-year')"
                      v-on:change="changeYear()" prepend-icon="mdi-calendar-month-outline">
            </v-select>
          </v-card-title>
          <v-card-text>
            <MonthIncomeChart :orders="orders" ref="currYearCompChart"></MonthIncomeChart>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card elevation="2">
          <v-card-title>{{$t('dashboard-items.revenue-by-years')}}, {{"\u20B4"}}</v-card-title>
          <v-card-text>
            <YearlyIncomeChart :orders="orders"></YearlyIncomeChart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="blue lighten-2"
              icon="mdi-calendar-refresh-outline"
              :title="$t('dashboard-items.per-year')"
              :value="'\u20B4 '+calculateAvgRevenue()"
              :sub-icon="avgRelative>=0?'mdi-arrow-up':'mdi-arrow-down'"
              :sub-icon-color="avgRelative>=0?'success':'error'"
              :sub-text="$t('dashboard-items.current-year')+': \u20B4 '+calculateExpected+ ' ('+(avgRelative>=0?'+'+avgRelative:avgRelative)+'%)'"/>


            <!--            <base-material-stats-card-->
            <!--              color="blue lighten-2"-->
            <!--              icon="mdi-calendar-refresh-outline"-->
            <!--              :title="$t('dashboard-items.per-year')"-->
            <!--              :value="'\u20B4 '+calculateAvgRevenue()"-->
            <!--              :sub-icon="avgRelative>=0?'mdi-arrow-up':'mdi-arrow-down'"-->
            <!--              :sub-icon-color="avgRelative>=0?'success':'error'"-->
            <!--              :sub-text="$t('dashboard-items.current-year')+': \u20B4 '+calculateExpected+ ' ('+avgRelative+'%)'"/>-->
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="light-green darken-2"
              icon="mdi-calendar-month-outline"
              :title="$t('dashboard-items.per-month')"
              :value="'\u20B4 '+avgMonth"
              :sub-icon="avgRelative>=0?'mdi-arrow-up':'mdi-arrow-down'"
              :sub-icon-color="avgRelative>=0?'success':'error'"
              :sub-text="$t('dashboard-items.current-year')+': \u20B4 '+avgMonthCurrent+ ' ('+(avgRelative>=0?'+'+avgRelative:avgRelative)+'%)'"/>
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="purple darken-1"
              icon="mdi-calendar-week"
              :title="$t('dashboard-items.per-week')"
              :value="'\u20B4 '+avgWeek"
              :sub-icon="avgRelative>=0?'mdi-arrow-up':'mdi-arrow-down'"
              :sub-icon-color="avgRelative>=0?'success':'error'"
              :sub-text="$t('dashboard-items.current-year')+': \u20B4 '+avgWeekCurrent+ ' ('+(avgRelative>=0?'+'+avgRelative:avgRelative)+'%)'"/>
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="amber darken-3"
              icon="mdi-calendar-today"
              :title="$t('dashboard-items.per-day')"
              :value="'\u20B4 '+avgDay"
              :sub-icon="avgRelative>=0?'mdi-arrow-up':'mdi-arrow-down'"
              :sub-icon-color="avgRelative>=0?'success':'error'"
              :sub-text="$t('dashboard-items.current-year')+': \u20B4 '+avgDayCurrent+ ' ('+(avgRelative>=0?'+'+avgRelative:avgRelative)+'%)'"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="6" sm="12">
        <v-card elevation="2">
          <v-card-title>{{$t('dashboard-items.monthly-average-income')}}, %</v-card-title>
          <v-card-text>
            <MonthIncomeYearlyChart :orders="orders"></MonthIncomeYearlyChart>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card elevation="2">
          <v-card-title>{{$t('dashboard-items.top-10-agencies')}} ({{$t('dashboard-items.by-revenue')}}, {{"\u20B4"}})
          </v-card-title>
          <v-card-text>
            <Top10AgenciesByRevenue :orders="orders"></Top10AgenciesByRevenue>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="4" sm="12">
        <base-material-stats-card
          color="info"
          icon="mdi-account-cash-outline"
          :title="$t('dashboard-items.total-debt')"
          :value="debtSum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')"
          sub-icon="mdi-alert"
          sub-icon-color="warning"
          :sub-text="$t('dashboard-items.debt-orders')+': '+calculateDebtOrders()"/>

        <base-material-stats-card
          color="orange"
          icon="mdi-domain"
          :title="$t('dashboard-items.agencies')"
          :value=agencies.length.toString()
          sub-icon="mdi-information-outline"
          sub-icon-color="primary"
          :sub-text="$t('dashboard-items.active-agencies')+': '+calculateActiveAgencies + ' ('+(activeAgencies/agencies.length*100).toFixed(1)+'%)'"/>

        <base-material-stats-card
          color="primary"
          icon="mdi-book-open-page-variant-outline"
          :title="$t('dashboard-items.pages')"
          :value=calculatePages()
          sub-icon="mdi-translate"
          sub-icon-color="primary"
          :sub-text="$t('dashboard-items.this-year')+': '+pagesYTD+' '+$t('dashboard-items.pages-s')+'  (+'+(pagesYTD*100/totalPages).toFixed(1)+'%) ['+$t('dashboard-items.total')+ ' '+totalChars+ ' '+$t('dashboard-items.symbols')+']'"/>
      </v-col>
      <v-col cols="12" md="8" sm="12">
        <v-card elevation="2">
          <v-card-title>{{$t('dashboard-items.top-10-agencies')}} ({{$t('dashboard-items.by-order-number')}})
          </v-card-title>
          <v-card-text>
            <Top10AgenciesByOrderNumber :orders="orders"></Top10AgenciesByOrderNumber>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <base-material-stats-card
      color="blue-grey lighten-1"
      icon="mdi-cash"
      :title="$t('dashboard-items.average-order-cost')"
      :value="'\u20B4 '+calculateAvgOrderCost"
      :sub-icon="orderCostRelative>=0?'mdi-arrow-up':'mdi-arrow-down'"
      :sub-icon-color="orderCostRelative>=0?'success':'error'"
      :sub-text="$t('dashboard-items.this-year')+': \u20B4 '+avgOrderCostYTD+ ' ('+(orderCostRelative>=0?'+'+orderCostRelative:orderCostRelative)+'%)'"/>

    <v-row justify="center" v-if="userReady">
      <v-col cols="12" md="6">
        <v-card elevation="2">
          <v-card-title>{{$t('dashboard-items.file-storage')}}</v-card-title>
          <v-card-text>
            <FileStorage :files="files" :userDB="userDB"></FileStorage>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card elevation="2">
          <v-card-title>{{$t('dashboard-items.data-storage')}}</v-card-title>
          <v-card-text>
            <DataStorage :orders="orders" :agencies="agencies" :managers="managers" :tasks="tasks" :payers="payers"
                         :userDB="userDB"></DataStorage>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4" sm="12">
        <v-card
          elevation="2">
          <v-card-title>{{$t('dashboard-items.recent-order-statuses')}}</v-card-title>
          <v-card-text>
            <BarChart :orders="orders"></BarChart>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="8" sm="12">
        <v-card
          elevation="2">
          <v-card-title>{{$t('dashboard-items.number-order-years')}}</v-card-title>
          <v-card-text>
            <YearlyOrdersChart :orders="orders"></YearlyOrdersChart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3" sm="12">
        <base-material-stats-card
          color="primary"
          icon="mdi-book-open-page-variant-outline"
          :title="$t('dashboard-items.pages-per-day')"
          :value=pagesPerDay
          :sub-icon="pagesPerDayYTD>=pagesPerDay?'mdi-arrow-up':'mdi-arrow-down'"
          :sub-icon-color="pagesPerDayYTD>=pagesPerDay?'success':'error'"
          :sub-text="$t('dashboard-items.this-year')+': '+pagesPerDayYTD+ ' ('+(pagesPerDayRelative>=0?'+'+pagesPerDayRelative:pagesPerDayRelative)+'%)'"/>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <base-material-stats-card
          color="info"
          icon="mdi-timer-sand-complete"
          :title="$t('dashboard-items.orders-in-progress')"
          :value=calculateInProgressOrders()
          sub-icon="mdi-alert"
          sub-icon-color="red"
          :sub-text="$t('dashboard-items.urgent')+': '+urgentOrders"/>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <base-material-stats-card
          color="indigo"
          icon="mdi-book-open-page-variant-outline"
          :title="$t('dashboard-items.pages-per-order')"
          :value=pagesPerOrder
          :sub-icon="pagesPerOrderYTD>=pagesPerOrder?'mdi-arrow-up':'mdi-arrow-down'"
          :sub-icon-color="pagesPerOrderYTD>=pagesPerOrder?'success':'error'"
          :sub-text="$t('dashboard-items.this-year')+': '+pagesPerOrderYTD+ ' ('+(pagesPerOrderRelative>=0?'+'+pagesPerOrderRelative:pagesPerOrderRelative)+'%)'"/>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <base-material-stats-card
          color="amber darken-2"
          icon="mdi-calendar-range"
          :title="$t('dashboard-items.orders-per-week')"
          :value=ordersPerWeek
          sub-icon="mdi-calendar"
          :sub-text="(ordersPerWeek/7).toFixed(2) +' '+$t('dashboard-items.orders-per-day')"/>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card elevation="2">
          <v-card-title>{{$t('dashboard-items.top-10-agencies-cy')}} ({{$t('dashboard-items.by-revenue')}},
            {{"\u20B4"}})
          </v-card-title>
          <v-card-text>
            <Top10AgenciesByRevenueCY :orders="YTDOrders"></Top10AgenciesByRevenueCY>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card elevation="2">
          <v-card-title>{{$t('dashboard-items.top-10-agencies-cy')}} ({{$t('dashboard-items.by-order-number')}})
          </v-card-title>
          <v-card-text>
            <Top10AgenciesByOrderNumber :orders="YTDOrders"></Top10AgenciesByOrderNumber>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import BarChart from "./charts/BarChart";
  import MonthIncomeChart from "./charts/MonthIncomeChart";
  import YearlyIncomeChart from "./charts/YearlyIncomeChart";
  import MonthIncomeYearlyChart from "./charts/MonthIncomeYearlyChart";
  import Top10AgenciesByOrderNumber from "./charts/Top10AgenciesByOrderNumber";
  import Top10AgenciesByRevenue from "./charts/Top10AgenciesByRevenue";
  import Top10AgenciesByRevenueCY from "./charts/Top10AgenciesByRevenueCY";
  import CurrentMonthComparisonChart from "./charts/CurrentMonthComparisonChart";
  import YearlyOrdersChart from "./charts/YearlyOrdersChart";
  import Loader from "../../components/Loaders/Loader";

  import {Chart} from 'chart.js'
  import ChartDataLabels from 'chartjs-plugin-datalabels';

  Chart.register(ChartDataLabels);

  import {useOrders} from "../../stores/ordersStore";
  import {useAgencies} from "../../stores/agenciesStore";
  import {mapState} from "pinia";
  import {useUser} from "../../stores/userStore";
  import {usePayers} from "../../stores/payersStore";
  import {useFiles} from "../../stores/filesStore";
  import {useManagers} from "../../stores/managersStore";
  import {useTasks} from "../../stores/tasksStore";
  import DataStorage from "./charts/DataStorage";
  import FileStorage from "./charts/FileStorage";

  const agencyStore = useAgencies()
  const ordersStore = useOrders()
  const userStore = useUser()
  const payersStore = usePayers()
  const filesStore = useFiles()
  const managersStore = useManagers()
  const tasksStore = useTasks()

  export default {
    name: 'DashboardDashboard',
    components: {
      FileStorage,
      DataStorage,
      Loader,
      BarChart,
      MonthIncomeChart,
      YearlyIncomeChart,
      MonthIncomeYearlyChart,
      Top10AgenciesByOrderNumber,
      Top10AgenciesByRevenue,
      Top10AgenciesByRevenueCY,
      CurrentMonthComparisonChart,
      YearlyOrdersChart,
    },
    data() {
      return {
        monthlyAvg: 0,
        monthNo: new Date().getMonth(),
        months: [{name: this.$t('months.jan'), monthNo: 0}, {
          name: this.$t('months.feb'),
          monthNo: 1
        }, {name: this.$t('months.mar'), monthNo: 2},
          {name: this.$t('months.apr'), monthNo: 3}, {
            name: this.$t('months.may'),
            monthNo: 4
          }, {name: this.$t('months.jun'), monthNo: 5},
          {name: this.$t('months.jul'), monthNo: 6}, {
            name: this.$t('months.aug'),
            monthNo: 7
          }, {name: this.$t('months.sep'), monthNo: 8},
          {
            name: this.$t('months.oct'),
            monthNo: 9
          },
          {name: this.$t('months.nov'), monthNo: 10}, {name: this.$t('months.dec'), monthNo: 11}],
        userReady: false,
        today: new Date(),
        firstYearDay: new Date(new Date().getFullYear(), 0, 1),
        currYear: new Date().getFullYear(),
        selectedYear: new Date().getFullYear(),
        debtOrders: [],
        debtSum: '',
        inProgressOrders: [],
        urgentOrders: [],
        totalRevenue: '',
        revenueThisMonth: 0,
        thisMonth: '',
        lastMonth: '',
        percentChange: 0,
        totalChars: 0,
        avgYear: 0,
        avgMonth: 0,
        avgWeek: 0,
        avgDay: 0,
        avgYearCurrent: 0,
        avgMonthCurrent: 0,
        avgWeekCurrent: 0,
        avgDayCurrent: 0,
        avgRelative: 0,
        ordersPerWeek: '',
        totalPages: 0,
        pagesYTD: 0,
        pagesPerDay: 0,
        pagesPerDayYTD: 0,
        pagesPerDayRelative: 0,
        pagesPerOrder: 0,
        pagesPerOrderYTD: 0,
        pagesPerOrderRelative: 0,
        activeAgencies: 0,
        YTDRevenue: 0,
        YTDOrders: 0,
        MTDOrders: 0,
        totalAvgOrderCost: 0,
        avgOrderCostYTD: 0,
        orderCostRelative: 0
      }
    },
    methods: {
      changeMonth() {
        this.$refs.currMonthCompChart.groupOrders(this.monthNo)
      },
      changeYear() {
        this.$refs.currYearCompChart.groupOrders(this.selectedYear)
      },
      async updateNeededCheck() {
        if (!this.files.length) {
          filesStore.getAllFiles()
        }
        if (!this.agencies.length) {
          agencyStore.getAgencyList()
        }
        if (!this.tasks.length) {
          tasksStore.populateTasks()
        }
        if (!this.managers.length) {
          managersStore.populateManagers()
        }
        if (!this.payers.length) {
          payersStore.populatePayers()
        }
        if (!this.orders.length) {
          let res = await ordersStore.getOrdersAsync()
          ordersStore.getRecentOrders()
          ordersStore.generateNotifications(this.tasks, this.calculateFileStoragePercent, this.calculateDataStoragePercent)
        }
        await userStore.getUserAsync();
        console.log('user after...', this.userDB)
        this.userReady = true;
      },
      calculateAvgRevenue() {
        let datesArr = this.orders.map(((order) => order.orderDate));
        datesArr.sort(function (a, b) {
          return new Date(b) - new Date(a);
        });
        let firstOrderDate = datesArr[datesArr.length - 1];
        let lastOrderDate = datesArr[0];
        let d1 = new Date(lastOrderDate)
        let d2 = new Date(firstOrderDate)
        let daysDiff = (d1.getTime() - d2.getTime()) / (1000 * 3600 * 24);
        this.avgYear = this.totalRevenue / (daysDiff / 365);
        this.avgMonth = (this.totalRevenue / (daysDiff / 30.5)).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        this.avgWeek = (this.totalRevenue / (daysDiff) * 7).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
        this.avgDay = (this.totalRevenue / daysDiff).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
        this.ordersPerWeek = (this.orders.length / daysDiff * 7).toFixed(1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        return this.avgYear.toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
      },
      calculatePages() {
        let today = this.today;
        let firstYearDay = this.firstYearDay;

        let datesArr = this.orders.map(((order) => order.orderDate));
        datesArr.sort(function (a, b) {
          return new Date(b) - new Date(a);
        });

        let daysDiffYTD = (today.getTime() - firstYearDay.getTime()) / (1000 * 3600 * 24);

        let firstOrderDate = datesArr[datesArr.length - 1];
        let lastOrderDate = datesArr[0];
        let d1 = new Date(lastOrderDate)
        let d2 = new Date(firstOrderDate)
        let daysDiff = (d1.getTime() - d2.getTime()) / (1000 * 3600 * 24);

        this.totalChars = this.orders
          .map((order) => order.symbolCount)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

        let pages = this.orders
          .map((order) => order.pageCount)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0).toFixed(1);

        this.totalPages = pages;

        let pagesYTD = this.YTDOrders
          .map((order) => order.pageCount)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0);

        this.pagesYTD = pagesYTD.toFixed(1)

        let pagesPerDay = pages / daysDiff;
        let pagesPerDayYTD = pagesYTD / daysDiffYTD;
        let pagesPerDayRelative = (pagesPerDayYTD * 100 / pagesPerDay - 100).toFixed(1);

        pagesPerDay = pagesPerDay.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        pagesPerDayYTD = pagesPerDayYTD.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

        this.pagesPerDay = pagesPerDay
        this.pagesPerDayYTD = pagesPerDayYTD
        this.pagesPerDayRelative = pagesPerDayRelative

        let pagesPerOrder = (pages / this.orders.length).toFixed(2)
        let pagesPerOrderYTD = (pagesYTD / this.YTDOrders.length).toFixed(2)
        let pagesPerOrderRelative = (pagesPerOrderYTD * 100 / pagesPerOrder - 100).toFixed(1);
        this.pagesPerOrder = pagesPerOrder.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        this.pagesPerOrderYTD = pagesPerOrderYTD.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        this.pagesPerOrderRelative = pagesPerOrderRelative.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        return pages.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
      },
      calculateDebtOrders() {
        let debtOrders = this.debtOrders;
        debtOrders = this.orders.filter(e => e.status.statusName === 'Finished');
        this.debtSum = debtOrders
          .map((order) => order.totalCost)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0).toFixed(0);
        return debtOrders.length.toString();
      },
      calculateInProgressOrders() {
        let today = this.today;
        let tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 2);
        tomorrow.setHours(0, 0, 0, 0)
        let inProgressOrders = this.inProgressOrders;
        inProgressOrders = this.orders.filter(e => e.status.statusName === 'In progress');
        this.urgentOrders = inProgressOrders.filter(e => new Date(e.finishTime) < tomorrow).length.toString();
        return inProgressOrders.length.toString()
      },
    },
    computed: {
      ...mapState(useOrders, ['orders', 'recentOrders', 'loading', 'notifications']),
      ...mapState(useUser, ['userDB']),
      ...mapState(useAgencies, ['agencies']),
      ...mapState(usePayers, ['payers']),
      ...mapState(useFiles, ['files']),
      ...mapState(useManagers, ['managers']),
      ...mapState(useTasks, ['tasks']),

      calculateAvgOrderCost() {
        this.avgOrderCostYTD = (this.YTDRevenue / this.YTDOrders.length).toFixed(0)
        this.totalAvgOrderCost = (this.totalRevenue / this.orders.length).toFixed(0)
        this.orderCostRelative = (this.avgOrderCostYTD * 100 / this.totalAvgOrderCost - 100).toFixed(1);
        return this.totalAvgOrderCost
      },
      calculateExpected() {
        let today = this.today;
        let firstYearDay = this.firstYearDay;
        let d1 = new Date(today)
        let d2 = new Date(firstYearDay)
        let daysDiff = (d1.getTime() - d2.getTime()) / (1000 * 3600 * 24);

        this.avgYearCurrent = this.YTDRevenue / (daysDiff / 365)
        this.avgRelative = (this.avgYearCurrent * 100 / this.avgYear - 100).toFixed(1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        this.avgYearCurrent = this.avgYearCurrent.toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

        this.avgMonthCurrent = (this.YTDRevenue / (daysDiff / 30.5)).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        this.avgWeekCurrent = (this.YTDRevenue / (daysDiff) * 7).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        this.avgDayCurrent = (this.YTDRevenue / daysDiff).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        return this.avgYearCurrent
      },
      calculateUniqueYears() {
        let yearsArr = this.orders.map(((order) => new Date(order.orderDate).getFullYear()));
        yearsArr.sort(function (a, b) {
          return new Date(b) - new Date(a);
        });
        return yearsArr
      },
      calculateYTDRevenue() {
        let today = this.today;
        let firstYearDay = this.firstYearDay;
        this.YTDOrders = this.orders
          .filter(e => new Date(e.orderDate).getTime() >= firstYearDay.getTime() && new Date(e.orderDate).getTime() <= today.getTime())
        this.YTDRevenue = this.YTDOrders
          .map((order) => order.totalCost)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0).toFixed(0).toString();
        return this.YTDRevenue.toString()
      },
      calculateActiveAgencies() {
        this.activeAgencies = this.agencies.filter(e => e.active).length
        return this.activeAgencies;
      },
      calculateRevenue() {
        this.totalRevenue = this.orders
          .map((order) => order.totalCost)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0).toFixed(0).toString();
        return this.totalRevenue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
      },
      calculateRevenueThisMonth() {
        this.revenueThisMonth = this.orders
          .filter(e => new Date(e.orderDate).getMonth() === new Date().getMonth() && new Date(e.orderDate).getFullYear() === new Date().getFullYear())
          .map((order) => order.totalCost)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0).toFixed(0).toString();
        return this.revenueThisMonth.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
      },
      calculateRelativeChanges() {
        let today = this.today;
        let currMonth = today.getMonth();
        let currYear = today.getFullYear();
        let lastMonthYear = today.getFullYear();
        let lastMonth = today.getMonth() - 1;
        if (currMonth === 0) {
          lastMonthYear = today.getFullYear() - 1;
          lastMonth = 11;
        }
        let percentChange;
        this.thisMonth = this.orders
          .filter(e => new Date(e.orderDate).getMonth() === currMonth && new Date(e.orderDate).getFullYear() === currYear)
          .map((order) => order.totalCost)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0);
        this.lastMonth = this.orders
          .filter(e => new Date(e.orderDate).getMonth() === lastMonth && new Date(e.orderDate).getFullYear() === lastMonthYear)
          .map((order) => order.totalCost)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0);
        percentChange = (this.thisMonth * 100 / this.lastMonth - 100).toFixed(1);
        return percentChange
      },
    },
    created() {
      this.updateNeededCheck();
    },
    mounted() {
      if (!this.currentUser) {
        this.$router.push('/auth');
      }
    }
  }
</script>
