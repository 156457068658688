<script>
  import {Doughnut} from "vue-chartjs";

  export default {
    extends: Doughnut,
    name: 'FileStorage',
    props: ['files', 'userDB'],
    data() {
      return {
        barChartData: {
          labels: [],
          datasets: [
            {
              backgroundColor: [],
              data: []
            },
          ]
        },
        barChartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom'
            },
            datalabels: {
              backgroundColor: function (context) {
                return context.dataset.backgroundColor;
              },
              borderColor: 'white',
              borderRadius: 25,
              borderWidth: 1,
              color: 'white',
              display: function (context) {
                let dataset = context.dataset;
                return dataset.data[context.dataIndex];
              },
              font: {
                weight: 'normal'
              },
              padding: 3,
              formatter: Math.round,
              anchor: 'center',
            }
          }
        }
      }
    },
    methods: {
      calculateRecords() {
        let limit = this.userDB.tariffPlan.fileStorageLimit;
        let usedSpace = this.files
          .map((file) => file.fileSize)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0);
        let freeSpace = limit - usedSpace;

        let usage = [];
        let labels = [];
        let colors = ['rgba(210, 0, 0, 0.8)', 'rgba(130, 130, 130, 0.8)']

        let usedPercent = usedSpace * 100 / limit;
        let freePercent = freeSpace * 100 / limit;
        let usedSpaceMb = (usedSpace / 1000000).toFixed(0) + ' '+this.$t('units.mb')+','
        let freeSpaceMb = (freeSpace / 1000000).toFixed(0) + ' '+this.$t('units.mb')+','
        usage.push(usedPercent.toFixed(1));
        usage.push(freePercent.toFixed(1));

        labels.push(this.$t('dashboard-items.used-space') +' (' + usedSpaceMb + ' ' + (usedPercent).toFixed(1) + '%)')
        labels.push(this.$t('dashboard-items.free-space') +' (' + freeSpaceMb + ' ' + (freePercent).toFixed(1) + '%)')

        this.barChartData.datasets[0].data = usage;
        this.barChartData.datasets[0].backgroundColor = colors;
        this.barChartData.labels = labels;
      }
    },
    async mounted() {
      this.calculateRecords()
      this.renderChart(this.barChartData, this.barChartOptions);
    },
  };
</script>

<style scoped>

</style>
