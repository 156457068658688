<template>
  <v-container fluid>
    <v-card elevation="1">
      <v-card-title class="justify-center">
        <v-img :src="require(`@/assets/loaders/loader.gif`)" max-width="200" justify="center"></v-img>
      </v-card-title>
      <v-card-title class="justify-center">
        <h4 v-if="type==='order'">{{$t('loadings.loading-order')}}</h4>
        <h4 v-if="type==='dashboard'">{{$t('loadings.loading-dashboard')}}</h4>
        <h4 v-if="type==='general'">{{$t('loadings.loading-general')}}</h4>
        <h4 v-if="type==='calendar'">{{$t('loadings.loading-calendar')}}</h4>
        <h4 v-if="type==='agencies'">{{$t('loadings.loading-agencies')}}</h4>
        <h4 v-if="type==='files'">{{$t('loadings.loading-files')}}</h4>
        <h4 v-if="type==='file'">{{$t('loadings.loading-file')}}</h4>
        <h4 v-if="type==='references'">{{$t('loadings.loading-references')}}</h4>
      </v-card-title>
      <v-card-text class=text-center>
        <v-row justify="center">
          <v-col cols="6">
            <v-progress-linear color="success" indeterminate rounded height="6"></v-progress-linear>
            <br>
            <v-progress-linear color="error" reverse indeterminate rounded height="6">
            </v-progress-linear>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    props: ['type'],
    name: "Loader"
  }
</script>
