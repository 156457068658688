<script>
  import {Bar} from "vue-chartjs";

  export default {
    extends: Bar,
    name: 'MonthIncomeChart',
    props: ['orders'],
    data() {
      return {
        groupedOrders: [],
        barChartData: {
          labels: [],
          datasets: [
            {
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              data: [],
              borderColor: "rgba(75, 192, 192)",
              borderWidth: 2,
            }
          ]
        },
        barChartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              color: 'rgba(75, 192, 192, 0.7)',
              font: {
                weight: 'bold'
              },
              formatter: Math.round,
              anchor: 'end',
              align: 'start',
              offset: -20,
            },
          }
        }
      }
    },
    methods: {
      groupOrders(selectedYear) {
        //filtering orders by selected year
        let currentYear = selectedYear === undefined ? new Date().getFullYear() : selectedYear;
        let ordersYTD = this.orders.filter(e => new Date(e.orderDate).getFullYear() === currentYear)

        //Grouping orders by months (using Order Date)
        let months = []

        function groupItemBy(array, property) {
          var hash = {},
            props = property.split('.');
          for (var i = 0; i < array.length; i++) {
            var key = props.reduce(function (acc, prop) {
              return acc && acc[prop];
            }, array[i]);
            if (!hash[key]) hash[key] = [];
            hash[key].push(array[i]);
          }
          return hash;
        }

        let grouped = Object.values(groupItemBy(ordersYTD, 'orderDate'));

        //Adding grouped orders to month array, merging to unique months and sorting values.
        for (let i = 0; i < grouped.length; i++) {
          months.push(new Date(grouped[i][0].orderDate).getMonth())
        }
        const uniqueMonths = [...new Set(months)].sort(function compareNumbers(a, b) {
          return a - b;
        })

        //Converting month digits to String representation
        let uniqueMonthsStrings = []
        for (let i = 0; i < uniqueMonths.length; i++) {
          let b = "";
          switch (uniqueMonths[i]) {
            case 0:
              b = this.$t('months-short.jan');
              uniqueMonthsStrings.push(b)
              break;
            case 1:
              b = this.$t('months-short.feb');
              uniqueMonthsStrings.push(b)
              break;
            case 2:
              b = this.$t('months-short.mar');
              uniqueMonthsStrings.push(b)
              break;
            case 3:
              b = this.$t('months-short.apr');
              uniqueMonthsStrings.push(b)
              break;
            case 4:
              b = this.$t('months-short.may');
              uniqueMonthsStrings.push(b)
              break;
            case 5:
              b = this.$t('months-short.jun');
              uniqueMonthsStrings.push(b)
              break;
            case 6:
              b = this.$t('months-short.jul');
              uniqueMonthsStrings.push(b)
              break;
            case 7:
              b = this.$t('months-short.aug');
              uniqueMonthsStrings.push(b)
              break;
            case 8:
              b = this.$t('months-short.sep');
              uniqueMonthsStrings.push(b)
              break;
            case 9:
              b = this.$t('months-short.oct');
              uniqueMonthsStrings.push(b)
              break;
            case 10:
              b = this.$t('months-short.nov');
              uniqueMonthsStrings.push(b)
              break;
            case 11:
              b = this.$t('months-short.dec');
              uniqueMonthsStrings.push(b)
              break;
          }
        }

        //Getting total cost for each month
        let totalRevenueByMonth = []
        for (let i = 0; i < uniqueMonths.length; i++) {
          totalRevenueByMonth.push(ordersYTD.filter(e => new Date(e.orderDate).getMonth() === uniqueMonths[i])
            .map((order) => order.totalCost)
            .reduce(function (previous, current) {
              return previous + current;
            }, 0).toFixed(0))
        }

        //Pushing the calculated data to chart arrays (data/labels separately)
        this.barChartData.datasets[0].data = totalRevenueByMonth;
        this.barChartData.labels = uniqueMonthsStrings;

        this.renderChart(this.barChartData, this.barChartOptions);
      }
    },
    async mounted() {
      this.groupOrders()
    },
  };
</script>

<style scoped>

</style>
