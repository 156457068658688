<script>
  import {Bar} from "vue-chartjs";

  export default {
    extends: Bar,
    name: 'CurrentMonthComparisonChart',
    props: ['orders'],
    data() {
      return {
        type: 'horizontalBar',
        groupedOrders: [],
        barChartData: {
          labels: [],
          datasets: [
            {
              borderColor: [],
              backgroundColor: [],
              data: [],
              borderWidth: 1,
            }
          ]
        },
        barChartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              color: function(context) {
                return context.dataset.borderColor;
              },
              anchor: 'end',
              align: 'start',
              offset: -20,
            },
          }
        }
      }
    },
    methods: {
      groupOrders(mon) {
        let colors = ['rgba(255, 99, 132, 0.2)',
          'rgba(255, 12, 36, 0.2)',
          'rgba(22, 255, 122, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(215, 122, 192, 0.2)',
          'rgba(30, 11, 241, 0.2)',
          'rgba(150, 150, 200, 0.2)',
          'rgba(240, 147, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(201, 203, 207, 0.2)']
        let borderColors = ['rgba(255, 99, 132)',
          'rgba(255, 12, 36)',
          'rgba(22, 255, 122)',
          'rgba(75, 192, 192)',
          'rgba(215, 122, 192)',
          'rgba(30, 11, 241)',
          'rgba(150, 150, 200)',
          'rgba(240, 147, 64)',
          'rgba(255, 205, 86)',
          'rgba(75, 192, 192)',
          'rgba(54, 162, 235)',
          'rgba(153, 102, 255)',
          'rgba(201, 203, 207)']
        let orders = this.orders;
        let currentMonth = mon === undefined ? new Date().getMonth() : mon;
        let monthOrders = orders
          .filter(e => new Date(e.orderDate).getMonth() === currentMonth)

        function groupItemBy(array, property) {
          var hash = {},
            props = property.split('.');
          for (var i = 0; i < array.length; i++) {
            var key = props.reduce(function (acc, prop) {
              return acc && acc[prop];
            }, array[i]);
            if (!hash[key]) hash[key] = [];
            hash[key].push(array[i]);
          }
          return hash;
        }

        let grouped = Object.values(groupItemBy(orders, 'orderDate'));

        let years = []
        for (let i = 0; i < grouped.length; i++) {
          years.push(new Date(grouped[i][0].orderDate).getFullYear())
        }
        const uniqueYears = [...new Set(years)].sort(function compareNumbers(a, b) {
          return a - b;
        })

        //Getting total cost of month per each year
        let totalRevenueByMonth = []
        for (let i = 0; i < uniqueYears.length; i++) {
          totalRevenueByMonth.push(monthOrders.filter(e => new Date(e.orderDate).getFullYear() === uniqueYears[i])
            .map((order) => order.totalCost)
            .reduce(function (previous, current) {
              return previous + current;
            }, 0))
        }

        //Pushing the calculated data to chart arrays (data/labels separately)
        this.barChartData.datasets[0].data = totalRevenueByMonth;
        this.barChartData.labels = uniqueYears;

        let sum = totalRevenueByMonth.reduce((partialSum, a) => partialSum + a, 0);
        let average = (sum / uniqueYears.length).toFixed(0);
        this.$emit('average', average)

        this.barChartData.datasets[0].backgroundColor = colors;
        this.barChartData.datasets[0].borderColor = borderColors;
        this.renderChart(this.barChartData, this.barChartOptions);
      }

    },
    async mounted() {
      this.groupOrders()
      // this.renderChart(this.barChartData, this.barChartOptions);
    },
  };
</script>

<style scoped>

</style>
