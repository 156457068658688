<script>
  import {Doughnut} from "vue-chartjs";

  export default {
    extends: Doughnut,
    name: 'DataStorage',
    props: ['orders', 'agencies', 'tasks', 'payers', 'managers', 'userDB'],
    data() {
      return {
        barChartData: {
          labels: [],
          datasets: [
            {
              backgroundColor: [],
              data: []
            },
          ]
        },
        barChartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom'
            },
            datalabels: {
              backgroundColor: function (context) {
                return context.dataset.backgroundColor;
              },
              borderColor: 'white',
              borderRadius: 25,
              borderWidth: 1,
              color: 'white',
              display: function (context) {
                let dataset = context.dataset;
                return dataset.data[context.dataIndex];
              },
              font: {
                weight: 'normal'
              },
              padding: 3,
              formatter: Math.round,
              anchor: 'center',
            }
          }
        }
      }
    },
    methods: {
      calculateRecords() {
        let orders = this.orders;
        let agencies = this.agencies;
        let tasks = this.tasks;
        let managers = this.managers;
        let payers = this.payers;

        let limit = this.userDB.tariffPlan.dataStorageLimit;
        let usedRecords = orders.length + tasks.length + agencies.length + managers.length + payers.length;
        let freeSpace = limit - usedRecords;
        let entities = [orders, agencies, managers, payers, tasks];
        let entityNames = [this.$t('entities.orders'), this.$t('entities.agencies'), this.$t('entities.managers'), this.$t('entities.payers'), this.$t('entities.tasks')];

        let usageByRecordType = [];
        let labels = [];
        let colors = [
          'rgba(255, 190, 0, 0.8)',
          'rgba(75, 0, 130, 0.8)',
          'rgba(255, 140, 0, 0.8)',
          'rgba(0, 206, 209, 0.8)',
          'rgba(46, 204, 113, 0.8)',
          'rgba(130, 130, 130, 0.8)']

        for (let i = 0; i < entities.length; i++) {
          let usage = entities[i].length * 100 / limit;
          usageByRecordType.push(usage);
        }
        usageByRecordType.push(freeSpace * 100 / limit);

        for (let i = 0; i < entityNames.length; i++) {
          labels.push(entityNames[i] + ' (' + (usageByRecordType[i]).toFixed(1) + '%)')
        }
        labels.push(this.$t('dashboard-items.free-space') + ' (' + (freeSpace * 100 / limit).toFixed(1) + '%)')

        this.barChartData.datasets[0].data = usageByRecordType;
        this.barChartData.datasets[0].backgroundColor = colors;
        this.barChartData.labels = labels;
      }
    },
    async mounted() {
      this.calculateRecords()
      this.renderChart(this.barChartData, this.barChartOptions);
    },
  };
</script>

<style scoped>

</style>
