<script>
  import {Doughnut} from "vue-chartjs";

  export default {
    extends: Doughnut,
    name: 'Top10AgenciesByRevenue',
    props: ['orders'],
    data() {
      return {
        groupedOrders: [],
        barChartData: {
          labels: [],
          datasets: [
            {
              backgroundColor: [],
              data: []
            },
          ]
        },
        barChartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom'
            },
            datalabels: {
              backgroundColor: function (context) {
                return context.dataset.backgroundColor;
              },
              borderColor: 'white',
              borderRadius: 25,
              borderWidth: 1,
              color: 'white',
              display: function (context) {
                let dataset = context.dataset;
                return dataset.data[context.dataIndex];
              },
              font: {
                weight: 'normal'
              },
              padding: 3,
              formatter: Math.round,
              anchor: 'center',
            }
          }
        }
      }
    },
    methods: {
      groupOrders() {
        let orders = this.orders;
        let agencies = []
        let qts = []
        let colors = ['rgba(250, 190, 25, 0.8)',
          'rgba(255, 12, 36, 0.8)',
          'rgba(240, 147, 240, 0.8)',
          'rgba(75, 10, 56, 0.8)',
          'rgba(99, 100, 250, 0.8)',
          'rgba(201, 85, 12, 0.8)',
          'rgba(75, 192, 41, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(14, 102, 255, 0.8)',
          'rgba(255, 99, 132, 0.8)',
          'rgba(22, 255, 122, 0.8)']

        function groupItemBy(array, property) {
          var hash = {},
            props = property.split('.');
          for (var i = 0; i < array.length; i++) {
            var key = props.reduce(function (acc, prop) {
              return acc && acc[prop];
            }, array[i]);
            if (!hash[key]) hash[key] = [];
            hash[key].push(array[i]);
          }
          return hash;
        }

        let grouped = Object.values(groupItemBy(orders, 'agency.agencyId'));

        for (let i = 0; i < grouped.length; i++) {
          let agencyObj = {agencyName: grouped[i][0].agency.agencyName, orderQty: grouped[i].length}
          agencies.push(agencyObj)
        }
        agencies.sort((a, b) => {
          return b.orderQty - a.orderQty;
        });
        let topAgencies = agencies.slice(0, 10);

        let top10OrdersQty
        for (let i = 0; i < topAgencies.length; i++) {
          top10OrdersQty = topAgencies.map((order) => order.orderQty)
            .reduce(function (previous, current) {
              return previous + current;
            }, 0)
        }

        let labels = []
        let allOrdersQty = orders.length
        labels.push(this.$t('dashboard-items.other-agencies')+' (' + ((allOrdersQty - top10OrdersQty) * 100 / orders.length).toFixed(1) + '%)')
        qts.push(allOrdersQty - top10OrdersQty)

        for (let i = 0; i < topAgencies.length; i++) {
          labels.push(topAgencies[i].agencyName + ' (' + (topAgencies[i].orderQty * 100 / orders.length).toFixed(1) + '%)')
          qts.push(topAgencies[i].orderQty)
        }

        this.barChartData.datasets[0].data = qts;
        this.barChartData.datasets[0].backgroundColor = colors;
        this.barChartData.labels = labels;
      }
    },
    async mounted() {
      this.groupOrders()
      this.renderChart(this.barChartData, this.barChartOptions);
    },
  };
</script>
