import {defineStore} from 'pinia'
import {AXIOS} from "../../http-commons";

export const useManagers = defineStore('managersStore', {
  id: 'managers',
  state: () => ({
    managers: [],
    loading: false
  }),
  actions: {
    addManager(manager) {
      this.managers.unshift(manager);
      this.managers.sort((a, b) => (a.modifiedTime > b.modifiedTime) ? -1 : 1);
    },
    patchManager(manager) {
      let elIndex = this.managers.findIndex((elem => elem.managerId === manager.managerId));
      this.managers[elIndex] = manager;
      this.managers.sort((a, b) => (a.modifiedTime > b.modifiedTime) ? -1 : 1);
    },
    deleteManager(managerId) {
      let elIndex = this.managers.findIndex((elem => elem.managerId === managerId));
      this.managers.splice(elIndex, 1);
    },
    deleteMultipleManagers(managerList) {
      for (let i = 0; i < managerList.length; i++) {
        let elIndex = this.managers.findIndex((elem => elem.managerId === managerList[i].managerId));
        this.managers.splice(elIndex, 1);
      }
    },
    populateManagers() {
      let userId = JSON.parse(localStorage.getItem('user')).id
      let params = new URLSearchParams();
      params.append('userId', userId)
      AXIOS.get('/api/getAllManagers', {params})
        .then(response => {
          this.managers = response.data;
          this.managers.sort((a, b) => (a.modifiedTime > b.modifiedTime) ? -1 : 1);
        })
    }
  }
})
