import {defineStore} from 'pinia'
import {AXIOS} from "../../http-commons";
import EventBus from "../common/EventBus";
import {useUser} from "./userStore";
import 'element-ui/lib/theme-chalk/index.css';

export const useAgencies = defineStore('agenciesStore', {
  id: 'agencies',
  state: () => ({
    agencies: [],
  }),
  actions: {
    addAgency(agency) {
      this.agencies.unshift(agency);
    },
    patchAgency(agency) {
      let elIndex = this.agencies.findIndex((elem => elem.agencyId === agency.agencyId));
      this.agencies[elIndex] = agency;
    },
    deleteAgency(agencyId) {
      let elIndex = this.agencies.findIndex((elem => elem.agencyId === agencyId));
      this.agencies.splice(elIndex, 1);
    },
    async getAgenciesAsync() {
      this.loading = true;
      let params = new URLSearchParams();
      params.append('userId', JSON.parse(localStorage.getItem('user')).id)
      let res = await AXIOS.get('/api/getAllAgencies', {params})
      this.agencies = res.data
      this.agencies.sort((a, b) => (a.modifiedTime > b.modifiedTime) ? -1 : 1);
      this.loading = false;
      console.log("finished Async agencies")
      return res
    },
    getAgencyList() {
      let params = new URLSearchParams();
      params.append('userId', JSON.parse(localStorage.getItem('user')).id);
      console.log('receiving agencies...')
      AXIOS.get('/api/getAllAgencies', {params})
        .then(
          response => {
            this.agencies = response.data;
          },
          error => {
            if (error.response && error.response.status === 403) {
              const auth = useUser()
              auth.logoutNotification()
              EventBus.dispatch("logout");
            }
          })
    },
    getActiveAgencies() {
      let params = new URLSearchParams();
      params.append('userId', JSON.parse(localStorage.getItem('user')).id);
      console.log('receiving active agencies...')
      AXIOS.get('/api/getActiveAgencies', {params})
        .then(
          response => {
            this.agencies = response.data;
          },
          error => {
            if (error.response && error.response.status === 403) {
              const auth = useUser()
              auth.logoutNotification()
              EventBus.dispatch("logout");
            }
          })
    },
  }
})
