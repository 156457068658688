import {defineStore} from 'pinia'
import {AXIOS} from "../../http-commons";

export const useFiles = defineStore('filesStore', {
  id: 'files',
  state: () => ({
    files: [],
    loading: false
  }),
  actions: {
    addFile(file) {
      this.files.unshift(file);
      console.log("adding... " + file.fileId)
      this.files.sort((a, b) => (a.createdTime > b.createdTime) ? -1 : 1);
    },
    patchFile(file) {
      let elIndex = this.files.findIndex((elem => elem.fileId === fileId));
      this.files[elIndex] = file;
      this.files.sort((a, b) => (a.createdTime > b.createdTime) ? -1 : 1);
    },
    deleteFile(fileId) {
      console.log("before... " + this.files.length)
      console.log("deleting... " + fileId)
      let elIndex = this.files.findIndex((elem => elem.fileId === fileId));
      console.log("index... " + elIndex)
      this.files.splice(elIndex, 1);
      console.log("after... " + this.files.length)
    },
    deleteMultipleFiles(fileList) {
      for (let i = 0; i < fileList.length; i++) {
        let elIndex = this.files.findIndex((elem => elem.fileId === fileList[i].fileId));
        this.files.splice(elIndex, 1);
      }
    },
    async getFilesAsync() {
      let params = new URLSearchParams();
      params.append('userId', JSON.parse(localStorage.getItem('user')).id)
      let res = AXIOS.get('/api/getAllTranslationFiles', {params})
      let fls = await res;
      this.files = fls.data;
      return res;
    },
    getAllFiles() {
      let params = new URLSearchParams();
      params.append('userId', JSON.parse(localStorage.getItem('user')).id);
      AXIOS.get('/api/getAllTranslationFiles', {params})
        .then(response => {
          this.files = response.data;
        })
    },
  }
})
