<script>
  import {Doughnut} from "vue-chartjs";

  export default {
    extends: Doughnut,
    name: 'BarChart',
    props: ['orders'],
    data() {
      return {
        groupedOrders: [],
        barChartData: {
          labels: [],
          datasets: [
            {
              // borderColor: [],
              backgroundColor: [],
              data: []
            },
          ]
        },
        barChartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom'
            },
            datalabels: {
              backgroundColor: function (context) {
                return context.dataset.backgroundColor;
              },
              borderColor: 'white',
              borderRadius: 25,
              borderWidth: 1,
              color: 'white',
              display: function (context) {
                let dataset = context.dataset;
                return dataset.data[context.dataIndex];
              },
              font: {
                weight: 'normal'
              },
              padding: 3,
              formatter: Math.round,
              anchor: 'center',
            }
          },
          rotation: -90, // start angle in radians
          circumference: 180, // sweep angle in radians
        }
      }
    },
    methods: {
      groupOrders() {
        let orders = this.orders;

        function groupItemBy(array, property) {
          var hash = {},
            props = property.split('.');
          for (var i = 0; i < array.length; i++) {
            var key = props.reduce(function (acc, prop) {
              return acc && acc[prop];
            }, array[i]);
            if (!hash[key]) hash[key] = [];
            hash[key].push(array[i]);
          }
          return hash;
        }

        orders = orders
          .filter(e => e.status.statusName === 'Finished' || e.status.statusName === 'In progress' || e.status.statusName === 'New')
        let ordersNo = orders.length

        let grouped = Object.values(groupItemBy(orders, 'status.statusName'));

        let statuses = []
        let qts = []
        let colors = []
        // let borderColors = []
        for (let i = 0; i < grouped.length; i++) {
          if (grouped[i][0].status.statusName === 'New') {
            colors.push('rgba(244,67,54, 0.7)')
            // borderColors.push('rgba(244,67,54, 0.8)')
          }
          if (grouped[i][0].status.statusName === 'In progress') {
            colors.push('rgba(255,193,7, 0.7)')
            // borderColors.push('rgba(255,193,7, 0.8)')
          }
          if (grouped[i][0].status.statusName === 'Finished') {
            colors.push('rgba(63,81,181, 0.7)')
            // borderColors.push('rgba(63,81,181, 0.8)')
          }
          qts.push(grouped[i].length)
          statuses.push(grouped[i][0].status.statusName + ' (' + (grouped[i].length * 100 / ordersNo).toFixed(1) + '%)')
        }

        this.barChartData.datasets[0].data = qts;
        this.barChartData.datasets[0].backgroundColor = colors;
        // this.barChartData.datasets[0].borderColor = borderColors;
        this.barChartData.labels = statuses;
      }
    },
    async mounted() {
      this.groupOrders()
      this.renderChart(this.barChartData, this.barChartOptions);
    },
  };
</script>

<style scoped>

</style>
